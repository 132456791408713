.searchdropdown {
  select {
    display: none !important;
  }

  .select--group--label {
    display: block;
    width: 100%;
    overflow: hidden;

    em {
      float: right;
      font-style: inherit;
      background-color: $shadowGrey;
      border-radius: 10px;
      color: $gray;
      padding: 2px 8px;
      text-align: center;
    }
  }

  label {
    display: block;
    margin: 0 0 4px;
  }
}

.infoPanel {
  color: #fff;
  padding: 0;
  height: auto;
  opacity: 0;
  max-height: 0;
  transition: 0.25s all ease;

  p {
    width: 85%;
    text-align: center;
    margin: 0;
  }

  &--red {
    background-color: $red;
  }

  &--orange {
    background: $orange;
  }

  &__toggle {
    cursor: pointer;
    padding: size(5);
  }

  &.active {
    opacity: 1;
    max-height: 100px;
    padding: size(13);
  }
}

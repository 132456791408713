/** Overrides **/
.DateInput {
  width: 200px;
  font-weight: 300;
  font-size: 1rem;
}

.DateInput input {
  font-size: 1rem;
}

.SingleDatePickerInput button {
  padding: 0;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  background-color: $orange;
  border-color: #e4e7e7;
}

.SingleDatePickerInput {
  display: flex;
  height: size(25);
}

.SingleDatePickerInput_calendarIcon {
  display: flex;
}

.CalendarMonth_caption {
  text-transform: uppercase;
}
.CalendarMonth_caption strong {
  font-weight: 200;
}
.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}
.SingleDatePicker_picker {
  top: 40px !important;
  border-radius: 4px !important;
}
.DateInput_fang {
  top: 29px !important;
}

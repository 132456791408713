.ask-reform {
  &--title {
    cursor: pointer;

    .icons {
      display: inline-block;
    }
  }

  &--content {
    padding: 20px;
  }

  &--list {
    display: flex;
    flex-direction: column;

    &-item {
      margin: 10px 0;

      label:hover {
        cursor: pointer;
      }
    }
  }

  &--spinner {
    position: relative;
    padding: 20px;
    min-height: 100px;
  }
}

#ask-reform--form-submit {
  margin: 25px 0;
}

.ask-reform--content h4 {
  display: flex;
  flex-direction: row;
}

.reform-reasons {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center
}


.reform-reasons p {
  margin: 0;
  padding: 0;
}

.reform-reasons-required {
  font-weight: 700;
}
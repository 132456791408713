.print-container {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  padding: 1rem;

  &__card {
    position: relative;
    border: 1px dashed black;
    margin-bottom: 1rem;

    &:after {
      content: "";
      position: absolute;
      transition: 0.4s all ease;
      background-color: #2185d0;
      opacity: 0;
      left: 0;
      top: 0;
    }

    h2 {
      color: black;
      font-weight: bold;
      padding: 8px;
    }

    .active {
      &:after {
        opacity: 0.2;
      }
    }
  }
}

#react-modal {
  width: auto;
}
.transfer-summary {
  padding: 2rem;
  border-radius: 7px;
  border-width: 5px;
  border-style: solid;

  &--success {
    border-color: $green;

    .transfer-summary--title {
      color: $green;
    }
  }

  &--error {
    border-color: $red;

    .transfer-summary--title {
      color: $red;
    }
  }

  &--title {
    display: flex;
    align-items: center;
    margin: 1rem 0 3rem;

    h2 {
      margin: 0;
    }

    i.icon {
      font-size: 2rem;
      height: 2rem;
      width: 5rem;
    }
  }

  &--body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i.icon {
      font-size: 3rem;
      height: 3rem;
    }
  }

  &--tag {
    margin-bottom: 2rem;
  }

  &--label {
    font-size: 1.3rem;
    margin-bottom: .5rem;
  }

  &--agent {
    display: flex;
    align-items: center;
    margin: 2rem;

    i.icon {
      font-size: 2rem;
      height: 2rem;
    }
  }

  &--actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  &--print {
    border: 1px solid $lighterGray;
    border-radius: 5px;
    margin: 1rem 0;

    .print-container {
      background-color: transparent;
    }

    .ui.button {
      padding: 0.75rem!important;
      margin-bottom: 0.25rem;
    }
  }
}
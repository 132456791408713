.welcomeBanner {
  display: flex;
  flex-direction: column;
  background: orange;
  border-radius: 8px;
  box-shadow: -7px -7px 18px 5px rgba(255, 255, 255, 0.8),
    5px 5px 18px 5px rgba(18, 61, 101, 0.2);
  color: #fff;
  grid-column: 1/3;
  padding: 40px 60px;

  &__title {
    margin: 0;
  }
}

.card {
  border-radius: 8px;
  background: linear-gradient(143.61deg, #ffffff 0%, #edf5fc 100%);
  box-shadow: -7px -7px 18px 5px rgba(255, 255, 255, 0.8),
    5px 5px 18px 5px rgba(18, 61, 101, 0.2);
  padding: 30px;
}

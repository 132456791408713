.tags_same_date {
  position: relative;
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 1.2em;
}

.tags_same_date .same_date_spinner {
  padding-top: 5rem;
}

.tags_same_date .delivery_listing {
  position: fixed;
  width: 21.5%;
  padding: 1rem;
  border: 1px solid #e0e0e0;
  background-color: white;
  z-index: 1;
}

.tags_same_date .delivery_code {
  font-weight: bold;
  cursor: pointer;
}

.tags_same_date p {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tags_same_date ul {
  padding: 0;
  padding-left: 1.3rem;
  margin: 0;
}

.tags_same_date .tag_list span:first-of-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.tags_same_date li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.tags_same_date li > div {
  display: flex;
}

.tags_same_date li.current {
  list-style-type: disclosure-closed;
  font-weight: bold;
  color: black;
}

.tags_same_date ul.inside li.current {
  color: black;
}

.tags_same_date .tag_state {
  font-weight: bold;
  margin: 1em 0;
}

.tags_same_date .container_state {
  margin: 0;
}

.tags_same_date .container_list {
  margin-bottom: 1em;
}

.tags_same_date .text-green {
  color: #00b5ad;
}

.tags_same_date .text-red {
  color: #e74c3c;
}
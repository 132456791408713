/* Shared */
@import "../../shared/rpoppin";
@import "../../shared/form";
@import "../../shared/infobox";
@import "../../shared/button";
@import "../../shared/inputs";

.agentCard {
  display: flex;
  align-self: flex-end;

  &--reverse {
    flex-direction: row-reverse;

    .agentCard__info {
      text-align: left;
    }
  }

  &__info {
    text-align: right;
  }
}

.control {
  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--content {
    padding: 20px;
  }

  &--point {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;

    &--success {
      font-weight: bold;
      color: green;
    }

    &--error {
      font-weight: bold;
      color: red;
    }
  }

  &--actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--action {
    margin-top: 40px;
  }

  &--name {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &--level {
    padding: 5px 10px;
    border-radius: 100px;
    background-color: grey;
    margin-right: 10px;
    color: white;

    &--1 {
      background-color: green;
    }
    &--2 {
      background-color: orange
    }
    &--3 {
      background-color: red;
    }
  }
}

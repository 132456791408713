.logo {
  &_loading {
    justify-content: "center";
    display: "flex";

    img {
      height: 10rem;
    }
  }
}

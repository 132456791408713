.rdropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: size(10);
  border-radius: 3px;
  position: relative;

  &__input-container {
    display: flex;
    border: 1px solid $gray;
    border-radius: 3px;

    input {
      display: flex;
      flex: 1;
      cursor: pointer;
      border: none;
    }
  }

  &__icon {
    background-color: $orange;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__list {
    height: auto;
    max-height: 0;
    transition: 0.4s all ease;
    overflow-y: scroll;
    transition: 0.4s all ease;
    border-top: none;
    border: 1px solid rgba(0, 0, 0, 0);
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 27px;
    z-index: 100;

    &.active {
      max-height: 150px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      z-index: 105;
    }

    > div {
      padding: 0.7rem;
      cursor: pointer;

      &:hover {
        background-color: $shadowGrey;
      }

      &.inactive {
        background-color: $lighterGray;
        cursor: default;
        color: white;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;

    &.disabled {
      cursor: inherit;
    }

    i.icon {
      height:inherit;
    }

    div.inactive {
      background-color: $lighterGray;
      cursor: default;
    }
  }
}

.rcheckbox {
  display: flex;
  align-items: center;

  input {
    display: none;

    &:checked + span {
      background-color: $orange;
      border: 1px solid $orange;
      background-image: url(../../svg/checkmark.svg);
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  span {
    cursor: pointer;
    width: size(20);
    height: size(20);
    border-radius: 4px;
    margin-right: 1rem;
    transition: 0.3s all ease;
    border: 1px solid $lighterGray;
    background-color: $lighterGray;
  }
}

.inputDate {
  display: flex;
  align-items: center;

  .SingleDatePicker {
    margin-right: 1rem;
  }
}

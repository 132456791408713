.issue-editor {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 3rem;


  .hide {
    display: none;
  }

  .asker {
    width: 200px;
  }

  .cause {
    .action-button__btn,
    .action-button__list-item {
      text-transform: capitalize;
    }
  }

  .sub-part {
    margin-top: 4rem;

    .or {
      display: flex;
      justify-content: center;
      font-weight: bold;
      padding: 1rem;
      padding-bottom: 0;
    }
  }

  p {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  #agent-information,
  .nb_results {
    display: none;
  }

  .fileContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;

    img {
      display: flex;
      width: 45px;
      margin-right: 10px;

      &.anonymous {
        width: 24px;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        width: 100%;
      }
    }
  }

  input[type="text"],
  textarea {
    border: none;
    transition: 0.4s all ease;
    padding: 1rem;
    width: 100%;
    margin-bottom: 2rem;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &:hover {
      &:not(:focus) {
        background-color: rgba(0, 0, 0, 0.1);
      }

      cursor: pointer;
    }
  }

  textarea {
    width: 100%;
    height: 220px;
  }

  .selectBox {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;

    button {
      display: inline-block;
      border: none;
      padding: 1rem 1rem;
      margin: 0;
      text-decoration: none;
      font-family: sans-serif;
      font-size: 1rem;
      cursor: pointer;
      text-align: left;
      -webkit-appearance: none;
      -moz-appearance: none;
      transition: 0.4s all ease;
      background-color: #fff;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .linkBox {
    display: flex;
    flex-wrap: wrap;

    strong {
      width: 100%;
    }
    .link_container {
      padding: 1rem;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      width: 100%;

      a {
        width: auto;
      }
    }
  }

  .action-button__list {
    margin: 0;
    left: 0;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;

    &-item {
      flex-direction: row;
      display: flex;
    }
  }

  .activity-logger {
    &_screen {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &_form {
      display: flex;
      justify-content: space-between;

      input {
        margin: 0;
      }

      select,
      button {
        max-height: 50px;
      }

      textarea {
        width: 60%;
        font-size: 1rem;
        margin: 0;
        height: 50px;

        &.active {
          height: 220px;
        }
      }
    }

    .bubble .activity-logger_form {
      display: block;

      textarea {
        width: 100%;
        height: 220px;
      }
    }

    &_screen {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid;
      border-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding-bottom: 2rem;
      margin-top: 3rem;
    }

    &_line {
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-template-rows: auto;
      grid-gap: 1rem;
      padding: 1rem;

      .infos {
        display: flex;
        flex-direction: column;
      }

      .bubble {
        position: relative;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 1rem;

        &_activityType {
          position: absolute;
          left: 0;
          top: 100%;
          font-style: italic;

          &.bubble_update {
            left: auto;
            right: 0;
            cursor: pointer;
            text-decoration: underline;
          }
        }

        &_update {
          &-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
              margin-left: 10px;
            }
          }
        }
      }

      &.client-answer {
        .bubble {
          background-color: $orange;
        }
      }
    }
  }
}

.rbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  height: 2.5rem;
  border-radius: 5px;
  border: 0;
  font-size: $big;

  &--red {
    background-color: $red;
  }

  &--green {
    background-color: $green;
  }

  &--orange {
    background-color: $orange;
  }

  &--black {
    background-color: $black;
  }

  &--blue {
    background-color: $blue;
  }

  &--rounded {
    font-size: $normal;
    border-radius: 30px;
    height: auto;
    text-transform: initial;
    padding: 0.3rem;
    font-size: size(12);
  }
}

.ui.button {
  &--blue {
    background-color: $blue;
  }
  &--red {
    background-color: $red;
  }
}
@import "./utils/functions";
@import "./utils/variables";
@import "./utils/mixins.scss";
@import "./utils/colors";
@import "./shared/infoPanel";
@import "./shared/logo";

@import "./overrides/airbnb.scss";
// @import url('//fonts.googleapis.com/css?family=Lato:400,700&display=swap');

@include fontface("LatoRegular", "/web/assets/fonts/Lato/Lato-Regular");
@include fontface("LatoBold", "/web/assets/fonts/Lato/Lato-Bold");

@import "./modules/dotationTransfer/base.scss";
@import "./modules/deliveryListing/base.scss";
@import "./modules/tagsPrint/base.scss";
@import "./modules/issueEditor/base.scss";
@import "./modules/extranet/base.scss";
@import "./modules/transferTags/base.scss";
@import "./modules/userNotifications/base.scss";

@import "./shared/searchDropdown";

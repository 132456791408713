.fadeTranslate-enter {
  opacity: 0;
}

.fadeTranslate-enter.fadeTranslate-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in 500ms;
}

.fadeTranslate-exit {
  position: absolute;
  top: calc(58px + 14px);
  opacity: 1;
  width: calc(100% - 30px) !important;
}

.fadeTranslate-exit.fadeTranslate-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.infobox {
  cursor: pointer;
}
.infobox__head {
  display: flex;
}
.infobox__info-container {
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: 0.4s all ease;
}
.infobox__info-container.active {
  height: auto;
  max-height: 200px;
}
.infobox__info-container > div {
  display: flex;
  flex-direction: column;
}

body.new {
  padding: 0;
  margin: 0;
  background: #f2f7fd;
}

.mainLayout {
  display: grid;
  max-width: 1800px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 90px 1fr;
  grid-template-areas: "nav content";
  column-gap: 40px;
  padding: 20px;
  box-sizing: border-box;
}

.heading {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: red;
  margin-bottom: 20px;
}
.heading > div {
  display: flex;
  max-width: 1800px;
  padding: 2rem 2rem 2rem 170px;
  width: 100%;
}

.content {
  grid-area: content;
}

.content__extranet {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
}

.menuV2 {
  grid-area: nav;
  position: fixed;
  margin-top: 20px;
  width: 90px;
  background-color: #fff;
  border-radius: 5px;
  top: 0;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 4px 20px 0 rgba(18, 61, 101, 0.05);
}
.menuV2__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rform {
  &__row {
    margin-bottom: size(20);
  }

  &__info {
    margin-bottom: 0.5rem;

    &--bold {
      font-weight: bold;
      color: black;
    }
  }

  input,
  select {
    width: 100%;
    padding: 0.4rem;
    border: none;
    background-color: transparent;
    height: size(25);
    border-radius: 3px;

    &[type="checkbox"] {
      width: 30px;
      height: 30px;
      margin-right: 1rem;
    }

    &[type="number"] {
      border: 1px solid $gray;
    }
  }

  &__input {
    display: flex;
    margin-bottom: 0.5rem;
    border: 1px solid $gray;
    border-radius: 3px;
    overflow: hidden;

    &--column {
      flex-direction: column;
    }

    button {
      -webkit-appearance: none;
      border: none;
      background-color: $orange;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;

      svg {
        margin-left: 1rem;
      }
    }
  }
}

.notifications {
    display: block !important;
    position: absolute;
    top: 40px;
    width: 620px;
    height: auto;
    max-height: 460px;
    overflow: auto;
    background: white;
    right: 20px;
    border-radius: 3px;
    z-index: 5;
    padding: 14px;
    box-shadow: var(--overlay,0 4px 8px -2px rgba(9,30,66,0.25),0 0 1px rgba(9,30,66,0.31));
}

.notifications__header {
    justify-content: space-between;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h3 {
        margin: 0;
        padding: 0;
    }

    span {
        width: auto !important;
        cursor: pointer;
    }
}

.notifications .ant-list-item,
.notifications a {
    width: 100%;
    padding: 0;
}

.notifications .ant-list-item-meta-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notifications .ant-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-top: 10px;
}

.notification__dot {
    width: 7px;
    height: 6px;
    display: block;
    background-color: #1F72F2;
    border-radius: 50%;
    margin-right: 10px;
    align-self: flex-start;
    margin-top: 6px;
}

.notifications__category {
    align-self: flex-start;
    margin-top: 5px;
    color: gray;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 2px 10px;
    margin-right: 8px;
}

.header__icon.bell {
    color: white;
    cursor: pointer;
    margin-right: 18px;
    position: relative;
}

.ant-badge-count {
    position: absolute;
    top: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;
    right: 20px;
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff;
}

.ant-scroll-number-only {
    position: relative;
    display: inline-block;
    height: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}

.header__notif {
    position: relative;
}

.notifications__delete {
    cursor: pointer;
}
.rpoppin {
  display: flex;
  flex-direction: column;
  color: #7b7777;
  background-color: #fff;
  padding: size(20) size(0);
  transition: 0.4s all ease;
  font-family: "LatoRegular", sans-serif;
  transition: 0.4s all ease;
  // overflow: hidden;
  max-height: 800px;
  height: auto;
  font-size: size(14);

  @keyframes svgBounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
      opacity: 0.9;
      transform: scale(1.1);
    }
    80% {
      opacity: 1;
      transform: scale(0.89);
    }
    100% {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }

  div,
  span,
  h2,
  h3,
  p {
    font-family: inherit;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: size(30) !important;
    font-weight: $regular;
    font-size: size(18);
  }

  &__result {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__image {
      margin-bottom: 1rem;

      svg {
        animation-name: svgBounceIn;
        animation-duration: 450ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    }

    &__title {
      text-align: center;
      margin-bottom: 2rem;
      font-size: $normal;

      span {
        font-weight: bold;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      font-size: $big;
      margin-bottom: 1.5rem;
      justify-content: center;
      align-items: center;

      p {
        text-align: center;
      }

      &--red {
        color: $red;
      }
      &--green {
        color: $green;
      }
    }
  }

  &__loading {
    // height: 285px;

    &__title {
      span {
        font-weight: $bold;
      }
    }
  }
}

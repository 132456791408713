.modal {
  .content {
    display: flex!important;
    align-items: center;
  }

  &--icon {
    margin: 2rem;
    font-size: 2rem;
  }
}
@keyframes appear {
  from {
    max-height: 0px;
  }

  to {
    max-height: 100px;
  }
}

.infobox {
  border-radius: 20px;
  padding: 0.4rem;
  animation-name: appear;
  animation-duration: 400ms;

  &--red {
    background-color: $light-red;
    color: $red;

    .infobox__span {
      background-color: $red;
    }
  }

  &--orange {
    background-color: $light-orange;
    color: $orange;

    .infobox__span {
      background-color: $orange;
    }
  }

  &__head {
    align-items: center;
  }

  &__text {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  &__span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    color: #fff;
    font-weight: bold;
    border-radius: 50px;
    font-size: size(11);
  }

  &__show {
    font-style: italic;
    text-decoration: underline;
    margin-left: 1rem;
  }

  &__info-container {
    font-style: italic;

    &.active {
      margin-top: 1rem;
      padding: 0.5rem;
    }

    > div:first-child {
      margin-bottom: 1rem;
    }
  }
}

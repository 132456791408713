@import 'modal';
@import 'results';

.transfer-tags-page {
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 1rem 0;
  }
}

.transfer-tags-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .transfer-form-actions {
    flex: 0.1;
  }
}

.recipient-agent-container {
  padding: 1em;
  margin: 1em 0;
  background-color: $shadowGrey;
  border-radius: 5px;
  width: 100%;

  &.ui.form {
    input {
      border: 0;
    }
  }

  .rdropdown__icon {
    background-color: white;
  }

  .rdropdown__list {
    top: 2.7rem;
    min-height: 4rem;
  }
}

.scan-input-button {
  min-width: 6rem;

  &.focused {
    background-color: #00b5ad!important;
  }
}

.scan-spinner {
  position: relative;
  margin: .8rem auto 0;
}

.agent-informations {
  min-height: 150px;
  padding: 8px;

  &--name {
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  &--place {
    display: flex;
    align-items: flex-start;
  }
}

.transfer-actions {
  display: flex;
  justify-content: flex-end;
}

.tags-to-transfer {
  flex: 0.9;
  overflow-y: auto;

  &.shadow-container {
    box-shadow: 0 0 8px -5px rgba(84, 84, 84, .75) inset;
    -webkit-box-shadow: 0 0 8px -5px rgba(84, 84, 84, .75) inset;
    -moz-box-shadow: 0 0 8px -5px rgba(84, 84, 84, .75) inset;
    border-radius: 5px;
  }

  .tags {
    border-radius: 5px;
    padding: .5rem;
  }

  .tag {
    border: 1px solid $shadowGrey;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 1rem;

    > div {
      margin: .5rem 0;

      > i.icon {
        margin-right: 1rem;
      }
    }

    .wash-container {
      display: flex;
      align-items: center;

      .wash-count {
        color: $green;
        font-size: 2rem;
        font-weight: bold;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .state > span {
      color: white;
      padding: 0.3rem 1rem;
      border-radius: 5px;
    }

    .delete-tag {
      float: right;
    }

    &--action {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button.ui.button {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &--actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;

    .total {
      background: transparent;
      border: 3px solid $blue;
      border-radius: 3px;
      margin-left: -5px;
      padding: 1em 1.5em;

      span {
        font-weight: bold;
        color: $blue;
        font-size: 1.1rem;
      }
    }
  }
}

.text {
  &--red {
    color: $red;
  }
}

.refresh-button {
  min-width: 7rem;
}